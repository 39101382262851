<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/information_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<!-- 遍历 -->
		<div class="w-100" v-for="(fitem,findex) in typeList" :key="findex">
			<div class="block30"></div>
			<!-- 类型 -->
			<div class="huaxue_type w1260">
				<div class="title fl fontsize38">{{fitem.name}}</div>
				<div class="more fr fontsize22" @click="$util.routerPath('/infoItem',{type:'chanyezixun',uid:fitem.id,typeName:fitem.name})">查看更多</div>
			</div>
			<div class="huaxue_notice w1239" v-if="fitem.noticeList.length>0">
				<div class="left" @click="clickarticleDetail(fitem.noticeList[0].id)">
					<div class="imgbox"><img :src="fitem.noticeList[0].imgUrl || require('../assets/static/noimg.png')"></div>
					<div class="title clamp2 fontsize18">{{fitem.noticeList[0].title}}</div>
				    <div class="timerdiv">
						<div class="more fontsize16">查看详情</div>
						<div class="text fontsize16">{{fitem.noticeList[0].timer}}</div>
					</div>
				</div>
				<div class="right" v-if="fitem.noticeList.length>1">
					<div class="notice_item" v-for="(item,index) in fitem.noticeList" :key="index" v-if="index!=0" @click="clickarticleDetail(item.id)">
						<div class="title fl clamp fontsize18">{{item.title}}</div>
						<div class="timer fr fontsize18">{{item.releaseTime || item.timer}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			typeList:[],//类型栏目
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(20).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		this.getchanyezixunType()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		//获取通知公告
		getdataList(typeId) {
			var _this = this;
			return new Promise((resolve, reject) => {
			    if(true){
			    	var params = {
			    		currentPage: 1,
			    		pageSize: 6,
						realTimeTypeId:typeId
			    	};
			    	_this.$http.post('frontEnd/realTime/getchanyezixun', params).then(function(res) {
			    		//数据处理
			    		if (res.code == 200) {
			    			var records = res.data.records;
			    			if(records.length>0){
			    				// if(records[0].contents){
			    				// 	records[0]["body"] = _this.$util.filtersText(records[0].contents,218)
			    				// }
			    				records.forEach((item,index)=>{
			    					if(item.createDate){
			    						item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
			    					}
									if(item.releaseTime){
										item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
									}
			    				})
			    			}
							resolve(records)
			    		} else {
							reject([])
			    			_this.$confirm(res.message, '提示', {
			    				confirmButtonText: '确定',
			    				cancelButtonText: '取消',
			    				type: 'warning'
			    			}).then(() => {}).catch(() => {});
			    		}
			    	})	 
			    }else{
			        reject(false) 
			    }
			})
		},
		//(文章)获取类型
		async getchanyezixunType() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 10,
			};
			this.$http.post('frontEnd/realTime/getchanyezixunType', params).then(async function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					for(var i=0;i<records.length>0;i++){
						records[i]["noticeList"] = []
						//同步等待
						var datalist = await _this.getdataList(records[i].id)
						if(datalist&&datalist.length>0){
							records[i]["noticeList"] = datalist
						}
					}
					_this.typeList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
